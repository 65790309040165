// .fundMomo{
//   margin-top: 5%;


 


//   &__container{
// display: flex;
//   flex-direction: column;
//    width: 80%;
//   // height: 452px;
//   border-radius: 8px;
//   margin: 0 auto;
//   gap: 1rem;

//   max-width: 50rem;
//   background-color: #cccccc55;
//   padding: 2rem;

//       @media only screen and (min-width: $bp-small) {
//           margin-left: 15rem;
      
//         }

//   }


//             padding-bottom:20%;


// }



.fundMomo {
  // margin-top: 2%;





  &__container {
    display: flex;
    flex-direction: column;
    width: 90%;
    // height: 452px;
    border-radius: 8px;
    margin: 0 auto;
    gap: 1rem;

    max-width: 50rem;
    // background-color: #cccccc55;
    // padding: 2rem;

    @media only screen and (min-width: $bp-small) {
      // margin-left: 10rem;
      max-width: 60rem;
      margin-left: 0;

    }

  }


  padding-bottom:20%;


}

.sendmoneypagebreadcumb{

  font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
   
    padding: 2rem 0;
    border-radius: 10px;
    // width: 90%;
    margin: 0 auto;


  @media only screen and (min-width: $bp-small) {
        width: 100%;
      }


  &.backgroundColor {
    background-color: #FCFCFC;
      ;
      }


    p{
          color: #656565;

    }

    @media only screen and (min-width: $bp-small) {  
      &__left{

        width: 65%;
      }

      }


    &__container {

      display: flex;
      flex-direction: column;
      gap: 1rem;


      @media only screen and (min-width: $bp-small) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;




      }


    }


    &__sendm {
      width: 100%;

    }
}
