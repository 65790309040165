// .modal {

//     position: absolute;

//     background-color: rgba(255, 255, 255, 0.781);
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     // display: none;
//     // opacity: 0;
//     transition: all .25s linear;
//     z-index: -99;

//     &.isactive{

//         // display: initial;
//         opacity: 1;
//         transition: all .25s linear;
//             z-index: 99;
//     }

// }

// .modal__body {

//     width: 80%;
//         margin: 0 auto;
//         position: relative;
//         top: 50%;
//         left: 40%;
//         transform: translate(-50%, -50%);
//         background-color: #fff;
//         padding: 20px;
//         box-shadow: 0 0.5rem 2rem hsla(0, 7%, 42%, .245);
//         border: 1px solid #ccc;
//         z-index: 999999999;
   


//     @media only screen and (min-width: $bp-small) {
//         width: 40%;
        
         
//              top: 50%;
//             left: 10%;
//          }


   small {

    font-size: 1em ;
   }  



// }


// .modal__success {

//     color: green;
//     position: absolute;
//     top: 0;
//     padding: 1rem 0;
// }

// .small-text{


//     font-size: 1.4rem;
//     padding: 1rem 0;
//     font-family: Montserrat;
//         font-size: 16px;
//         font-weight: 600;
//         line-height: 24px;
//         letter-spacing: 0em;
//         text-align: left;



    

//     &.medium{

//         font-size: 1.4rem;
//     }



//     @media only screen and (min-width: $bp-small) {
//         font-size: 1.4rem;
//         padding: 1rem 0;


//     &.medium {
    
//             font-size: 1.5rem;
//         }
//     }
// }


// .requestlistmodal_container{


//     width: 100%;

//     li{
//     width: 100%;
//     }
// }


// .request__list{

//     height: 35rem;
// }



// .button-okay{

//     width: 10rem;
//     height: 4rem;
//     background-color: rgb(189, 141, 79);
//     border: none;
//     cursor: pointer;
//     color: white;

    
    
// }


// .border{
//     // border: 1px solid red;
// }

 


// //  .btn{
// //         display: inline-block;
// //             font-weight: 400;
// //             line-height: 1.5;
// //             color: #212529;
// //             text-align: center;
// //             text-decoration: none;
// //             vertical-align: middle;
// //             cursor: pointer;
// //             -webkit-user-select: none;
// //             user-select: none;
// //             // background-color: transparent;
// //             border: 1px solid transparent;
// //             padding: 0.5rem 0.9rem;
// //             font-size: 1.4rem;
// //             border-radius: 0.25rem;
// //             transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// //  }

//  .btn-soft-grey{

// background-color: #495057;
//  }


// //  .btn-primary {
// //      color: #fff;
// //      background-color: #b3860a;
// //      border-color: #405189;
// //      border: none;
   
// //  }


//  .link-success {
//      color: #b3860a;
     
//  }


//  .modalheader{

//     background-color: #fcfcfc;
//     border-bottom: 1px solid #2125292c;
//     height: 4rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;



//  }