
.pageform__section{
width: 100%;

}


.pageform{

  width: 100%;
  margin-top: 20%;
  grid-template-columns: repeat(2,1fr);
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $bp-small) {
  
     margin-top: 5%;
      display: grid;
  
    }


  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    height: 452px;
    border-radius: 8px;

    margin: 0 auto;
    // background-color: red;


    @media only screen and (min-width: $bp-small) {
    
       width: 370px;
    
      }
    

  }


  &__head{
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #344054;
  }

  &__form{

    display: flex;
    flex-direction: column;
    gap: 2rem;

  }

  &__smalltext{
    
      color:  #656565;
      font-family: Montserrat;
        font-size: 16px;
      margin-top: .5rem;
  }


  &__group {
    display: flex;
    flex-direction: column;
    position: relative;
  
    &.select{
      label{
        z-index: 5;
      }
    }


        @media only screen and (min-width: $bp-small) {
          // width: 40rem;
    
        }
    
  }

  &__label {

    font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #344054;
      position: absolute;
      top: -1rem;
      background-color: white;
      margin-left: 1.5rem;
      color: #000000;
      padding: 0 .5rem;
      // z-index: 9;

      
   }

  &__input{
    // width: 360px;
    height: 44px;
    padding: 10px, 17px, 10px, 14px;
    border-radius: 8px;
    border: 1px;
    border: 2px solid #EBEBEB;
    padding: 0 1rem 0;
    padding: 2.5rem 1.9rem;

    &:focus{
      border: none;
      outline: none;
      border: 2px solid #0000FF;;
    }


  }

  &__button {
    // width: 365px;
    height: 44px;
    border-radius: 8px;
    gap: 10px;
    background-color:  #FF7A00;
    outline: none;
    border: 0;
    color: white;
    cursor: pointer;
  }


  &__side {
    display: none;



    @media only screen and (min-width: $bp-small) {
      display: initial;
   
      }
  }

  &__remembermecontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    p{
      color: #FF7A00;;
    }
  }

  &__rememberme{

    display: flex;
    gap: .5rem;

  }

}

.pageform__select{

      // height: 44px;
        border-radius: 8px;
        border: 1px;
        border: 2px solid #EBEBEB;
        padding:  .5rem 0;
}