
.login__section{
width: 100%;

}


.login{

  width: 100%;
  margin-top: 20%;
  grid-template-columns: repeat(2,1fr);
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $bp-small) {
  
     margin-top: 5%;
      display: grid;
  
    }


  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    height: 452px;
    border-radius: 8px;

    margin: 0 auto;
    // background-color: red;


    @media only screen and (min-width: $bp-small) {
    
       width: 370px;
    
      }
    

  }


  &__head{
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #344054;

    span {
        font-size: 1.7rem;
        @media only screen and (min-width: $bp-small) {             
          font-size: 2.5rem;        
          }
      }


    img{
      width: 5rem;
      height: 5rem;

      

    @media only screen and (min-width: $bp-small) {
         width: 6rem;
         height: 6rem;
    
      }
    }
  }

  &__form{

    display: flex;
    flex-direction: column;
    gap: 2rem;

  }

  &__smalltext{
    //styleName: Text 1;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
  }


  &__group {
    display: flex;
    flex-direction: column;
    
  }

  &__label {

    font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #344054;
  }

  &__input{
    // width: 360px;
    height: 44px;
    padding: 10px, 17px, 10px, 14px;
    border-radius: 8px;
    border: 1px;
    border: 1px solid #D0D5DD;
    padding: 0 1rem 0;


  }

  &__button {
    // width: 365px;
    height: 44px;
    border-radius: 8px;
    gap: 10px;
    background-color:  #FF7A00;
    outline: none;
    border: 0;
    color: white;
    cursor: pointer;
  }


  &__side {
    display: none;



    @media only screen and (min-width: $bp-small) {
      display: initial;
   
      }
  }

  &__remembermecontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    p{
      color: #FF7A00;;
    }
  }

  &__rememberme{

    display: flex;
    gap: .5rem;

  }

}