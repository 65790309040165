.paybills {

// background: #FBFBFB;
    // width: 1072px;
    height: 406px;
    top: 623px;
    left: 328px;
    border-radius: 10px;
    padding: 1rem 0;
    width: 93%;
    margin: 0 auto;
    min-width: 56.9rem;
    // margin-top: 5%;
    display: none;



    @media only screen and (min-width: $bp-small) {

        width: 100%;
        margin: initial;
        margin-top: 2%;
        display: block;

    }


    &__container {
        width: 90%;
        // max-width: 80rem;
        margin: 0 auto;
        // margin-top: 5%;
        position: relative;


        @media only screen and (min-width: $bp-small) {
            width: 100%;
            margin-left: 0;
            padding-left: 1rem;;
        }
    }

    &__head {

        // font-family: Montserrat;
        // font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        // display: flex;
        // justify-content: space-between;
        width: 95%;
        // margin:  0 auto;
        padding: 1rem 0;



        p {
            cursor: pointer;

            // color:  #FF7A00;
            text-decoration: none;
            font-size: 1.5rem;


        }


    }




    &__item {

        font-size: 1.4rem;
        text-align: center;


        &.pethod {
            font-family: Montserrat;
            display: flex;
            align-items: center;
            // justify-content: center;
            gap: .5rem;

            span {
                width: 20%;
                // margin-left: 6.5rem;  
            }


            img {
                object-fit: contain;
                margin-left: 1rem;



            }

        }



        &.amount {
            font-family: Montserrat;

            font-weight: 700;
            line-height: 20px;
            display: flex;
            align-items: center;
            // justify-content: center;
            gap: .5rem;


        }

        &.date {
            font-family: Montserrat;

            font-weight: 400;
            line-height: 20px;
            color: #747373;
            font-weight: bold;
        }

        &.type {
            font-family: Montserrat;
            font-weight: 400;
            line-height: 20px;
        }

        &.status {

            font-family: Montserrat;
            font-weight: 400;
            padding: .4rem;
            border-radius: 40px;

        }



        &.actions {

            position: relative;
            cursor: pointer;
            font-size: 2.5rem;
        }

    }

    &__item.head {
        color:  #000000;;
        font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

        // text-align: center;


        &.amount {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
        }






    }



    &__list {
        display: flex;
        // margin-top: 1rem;
        justify-content: space-between;
        padding: 1rem 0;
        align-items: center;



        &.head{

        background: #FCFCFC;
            padding: 2rem 0;
            padding-left: 1rem;
                
        }



        li {
            width: 25%;
            position: relative;
            



            p {

                width: 50%;
                padding: .5rem;

                border-radius: 1.5rem;
                text-align: center;
                margin: 0 auto;
               


                width: 106px;
                height: 30px;
                top: 737px;
                left: 1137px;
                border-radius: 40px;


                @media only screen and (min-width: $bp-small) {
                    width: 70%;
                    margin-left: -2rem;
                }


                &.fail,
                &.rejected {
                    background-color: #FF7373;
                    color: white;
                }


                &.pending {
                    border: 1px solid #FF7A00;
                    color: #FF7A00;


                }

                &.successful {
                    background-color: #D3F5D3;
                    ;
                    color: white;

                    // border: 1px solid rgb(187, 230, 15);
                    color: #000000;


                }

                &.rejected {
                    background-color: rgb(230, 36, 15);
                    color: white;


                }

                &.cancelled {
                    background-color: #FF7373;
                    color: white;
                    font-family: Montserrat;
                    // font-size: 16px;



                }
            }
        }


    }

    ul {
        li {
            text-align: start;


            &.disable-li {
                pointer-events: none;
                user-select: none;
                color: gray;
            }
        }
    }




    &__amount {

        font-weight: 900;
        font-size: 1rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        &--cash {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;

            span {
                font-size: 1.3rem
            }

            img {
                object-fit: contain;
                width: 1rem;
            }
        }
    }







}



// .transactionslistmobile {
//     padding: 3rem 0;


//     &__container {

//         position: relative;
//     }


//     @media only screen and (min-width: $bp-small) {

//         display: none;
//     }

//     &__container {

//         width: 90%;
//         margin: 0 auto;
//         margin-top: 2rem;
//     }



//     ul {
//         display: flex;
//         flex-direction: column;
//         gap: 2rem;

//         li {

//             display: flex;
//             justify-content: space-between;
//             align-items: center;


//         }
//     }

//     &__middle {
//         display: flex;
//         align-items: center;
//         gap: 3rem;

//     }

//     &__info {
//         display: flex;
//         flex-direction: column;
//         gap: .5rem;


//         p {

//             font-size: 1.5rem;
//         }

//         span {
//             color: #ccc;
//         }
//     }

//     &__middleimg {

//         width: 4rem;


//     }

//     &__buttom {
//         display: flex;
//         align-items: center;
//         gap: .5rem;
//     }

//     &__status {
//         border-radius: 1.5rem;
//         font-size: 1.3rem;
//         padding: .3rem .5rem;
//         width: 10rem;
//         color: white;
//         text-align: center;
//     }

//     &__status.pending {
//         border: 1px solid #FF7A00;
//         color: white;
//         color: #FF7A00;


//     }

//     &__status.successful {
//         //  border: 1px solid rgb(187, 230, 15);
//         color: #000000;
//         background-color: #D3F5D3;
//         ;


//     }

//     &__status.rejected {
//         background-color: rgb(230, 36, 15);
//         color: white;


//     }




//     &__amount {

//         font-weight: 900;
//         font-size: 1rem;
//         display: flex;
//         gap: 2rem;
//         position: relative;


//         &--cash {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             gap: .5rem;

//             p {
//                 font-size: 1.2rem;
//             }

//             span {
//                 font-size: 1.4rem
//             }

//             img {
//                 object-fit: contain;
//                 width: 1rem;
//             }
//         }



//         p {}
//     }


//     &__actions {

//         cursor: pointer;

//         &.disable-li {
//             pointer-events: none;
//             user-select: none;
//             color: gray;
//         }
//     }
//     }


//     .billcategory{

//         ul {

//             display: grid;
//             grid-template-columns: 1fr 1fr 1fr;
//             gap: 1rem;

//             li{


//                 padding: 1rem;
//                 background-color: #FCFCFC;

            
//                 a{

//                     color: #000000;
//                     font-size: 1.4rem;
//                 }
                

//             }
//         }
//     }