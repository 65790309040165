.visacard {
  min-width: 292px;
  max-width: 292px;
  height: 172px;

  min-width: 247px;
  height: 141px;
  top: 188px;
  left: 1051px;
  background: linear-gradient(31.58deg, #93278f -2.49%, #29abe2 67.92%);
  border-radius: 1rem;
  // cursor: pointer;

  @media only screen and (min-width: $bp-small) {
    // min-width: initial;
    // // max-width: initial;
    // height: initial;

    min-width: 292px;
    max-width: 292px;
    height: 172px;
  }

  &.master {
    background: linear-gradient(167.6deg, #e42c66 -9.78%, #f55b46 107.04%);
  }

  &.balancecard {
    background: white;
    border: 1px solid #ff7a00;
    // color: beige;
    min-width: 300px;
    max-width: 300px;

    margin: 0 auto;

    @media only screen and (min-width: $bp-small) {
      margin: initial;
    }

    p {
      color: black;
    }
  }

  img {
    object-fit: contain;
    width: 2rem;

    @media only screen and (min-width: $bp-small) {
      gap: 3rem;
    }
  }

  &__item {
    &.balancecard {
      img {
        object-fit: contain;
        // width: 2rem;
      }

      display: flex;
      flex-direction: row;
    }

    p {
      font-size: 0.6rem;
    }
  }

  &__container {
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    // gap: 2rem;
    justify-content: space-between;

    @media only screen and (min-width: $bp-small) {
      // gap: 3rem;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;

    img {
      // width: 2.5rem;
    }
  }

  &__numbers {
    display: flex;

    img {
      object-fit: contain;
      width: 10rem;
    }

    p {
      color: #ffffff;
      // font-size: 2rem;

      @media only screen and (min-width: $bp-small) {
        // font-size: 3rem;
      }
    }
  }

  &__cardholder {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      flex-direction: column;
      // gap: .5rem;
      color: #ffffff;
      span {
        // font-size: 1.6rem;
      }
    }
  }
}

.balancecard {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-family: Montserrat;
      // font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;
    }
  }

  &__cardcurrency {
    display: flex;
    gap: 1rem;

    p {
      background-color: white;
      width: 3rem;
      width: 35.4px;
      height: 35px;
      top: 110px;
      left: 535px;
      border-radius: 10px;
      border: 1px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__numbers {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      // font-size: 1.4rem;
      background: black;
      padding: 0.8rem 1rem;
      color: white;
      cursor: pointer;
      border-radius: 1.5rem;
      transition: all 0.25s;

      &:active {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

// .balancecardv2 {
//         background-color: #FF7A00;
//         // width: 319px;
//         // height: 180px;
//         border-radius: 1rem;

//         &__container {
//                 // background-color: #FF7A00;
//                 width: 90%;
//                 margin: 0 auto;
//                 // height: 180px;
//                 height: 100%;
//                 border-radius: 10px;

//         }

//         &__list{
//             height: 100%;
//             display: flex;
//                 flex-direction: column;
//                 justify-content: space-around;

//         }

//     &__top {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         &--text{
//                 // font-size: 1.8rem;
//                 font-weight: 600;
//                 line-height: 2.2rem;
//                 letter-spacing: .1rem;
//                 text-align: left;
//                color: #FFFFFF;
//         }

//     }

//     &__cardcurrency {
//         display: flex;
//         gap: 1rem;

//         p {
//             background-color: white;
//             width: 3rem;
//             width: 35.4px;
//             height: 35px;
//             top: 110px;
//             left: 535px;
//             border-radius: 10px;
//             border: 1px;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//         }
//     }

//     &__numbers {
//         position: relative;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         &--digits{
//             font-size: 1.4rem;
//             font-weight: 600;
//             line-height: 43px;
//             letter-spacing: .2rem;
//             text-align: left;
//             color: #FFFFFF;
//         }

//         span {
//             // font-size: 1.4rem;
//             // background: black;
//             border: 1px solid white;
//             padding: .4rem 1rem;
//             color: white;
//             cursor: pointer;
//             border-radius: 1.5rem;
//             transition: all .25s;
//             width: 5rem;
//             text-align: center;

//            &:hover, &:active{
//                 background-color: white;
//                 color: #FF7A00;
//             }

//             &:active {

//                 background-color: rgba(0, 0, 0, 0.6);
//             }

//         }
//     }

//     &__analytics{

//         ul{

//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-left: -30px;

//         }

//         li{
//             display: flex;
//             align-items: center;
//             gap: 1rem;
//         }

//         span{
//             font-family: Montserrat;
//             // font-size: 14px;
//             font-weight: 600;
//             line-height: 17px;
//             letter-spacing: 0em;
//             text-align: left;
//             color:  #FFFFFFB2;;
//         }
//     }

//     &.active {

//         // opacity: .3;
//         animation: left 1s infinite ease-in-out;

//     }

// }

.balancecardv2 {
  background-color: #f25e0e;
  // width: 319px;
  // height: 180px;
  border-radius: 1rem;
  margin-bottom: 5%;

  &.quick {
    background-color: #62534e;
  }

  &__container {
    // background-color: #FF7A00;
    background-color: white;
    width: 90%;
    margin: 0 auto;
    height: 175px;
    // height: 100%;
    border-radius: 10px;
    padding: 1rem;

    &.quick {
      background-color: var(--vz-card-bg);
    }
  }

  &__list {
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    // gap: 0.8rem;

    &.quick__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--text {
      // font-size: 1.8rem;
      font-weight: 600;
      // line-height: 2.2rem;
      letter-spacing: 0.1rem;
      text-align: left;
      color: black;
    }
  }

  &__cardcurrency {
    display: flex;
    gap: 1rem;

    p {
      background-color: white;
      width: 3rem;
      width: 35.4px;
      height: 35px;
      top: 110px;
      left: 535px;
      border-radius: 10px;
      border: 1px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__numbers {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 2rem;
      margin-right: 0.6rem;
    }

    p {
      margin-top: initial;
    }

    &--digits {
      font-size: 1rem;
      font-weight: 600;
      // line-height: 43px;
      letter-spacing: 0.2rem;
      text-align: left;
      color: #f25e0e;
    }

    span {
      // font-size: 1.4rem;
      // background: black;
      border: 1px solid white;
      padding: 0.4rem 1rem;
      color: white;
      cursor: pointer;
      border-radius: 1.5rem;
      transition: all 0.25s;
      width: 5rem;
      text-align: center;

      &:hover,
      &:active {
        background-color: white;
        color: #ff7a00;
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__analytics {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -30px;
    }

    li {
      display: flex;
      align-items: center;
      gap: 1rem;

      figure {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f25e0e;
        height: 2.5rem;
        width: 2.5rem;

        border-radius: 50%;
        margin-bottom: 0;

        img {
          align-self: center;
          height: 1.5rem;
          width: 1.5rem;
          // width: 50%;
          object-fit: contain;
          color: white;
        }
      }
    }

    span {
      font-family: Montserrat;
      // font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;

      &.quick {
        color: var(--vz-heading-color);
      }
    }
  }

  &.active {
    // opacity: .3;
    animation: left 1s infinite ease-in-out;
  }
}

.loader {
  position: relative;
}

.loader:before,
.loader:after {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
  margin: 10px auto;
  border-radius: 50%;
  background: #000000;
  animation: left 1s infinite ease-in-out;
}

.loader:after {
  background: #ff3d00;
  animation: right 1s infinite ease-in-out;
}

@keyframes right {
  0%,
  100% {
    transform: translate(-15px);
  }

  50% {
    transform: translate(15px);
  }
}

@keyframes left {
  0%,
  100% {
    // transform: translateY(5px);
    transform: scale(0.99);
    // opacity: .3;
    background: #ae9292;
    // background: linear-gradient(167.6deg,#62534e, #ae9292, #8b8b8b 107.04%);
  }

  70% {
    // transform: translateY(-5px);
    //  transform: scale(1);
    // opacity: .9;
    background: #8b8b8b;
  }

  50% {
    // transform: translateY(-5px);
    transform: scale(1);
    // opacity: .9;
    background: #62534e;
  }
}
