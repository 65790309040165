h1 {
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
  color: $color-black;
}


li {
  list-style: none;
}


     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
       display: none;
       transition: all .3s;
     }
  
  
     .row-90 {
       width: 90%;
       margin: 0 auto;

        @media only screen and (min-width: $bp-small) {
          width: 100%;
          }
     }
  
     /* Firefox */
     input[type=number] {
       -moz-appearance: textfield;
              transition: all .3s;
     }




    //  a{
    //   color: black;
    //  }