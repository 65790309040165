.header{


   &__container {

    display: flex;
    justify-content: space-between;  
    width: 90%;
    padding: 2rem 0;
    margin: 0 auto;
    

    @media only screen and (min-width: $bp-small) {
           margin: initial;
            width: 96%;
        }
    }

    &__searcharea {
        display: flex;
        gap: 2rem;
        align-items: center;

       
        

       


        p{
            font-family: Poppins;
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
             text-align: left;
                        cursor: pointer;
            margin-bottom: initial;

            @media only screen and (min-width: $bp-small) {
                    font-size: 1.6rem;
                }
        }


                p.welcome {
                    font-family: Segoe UI;
                    font-size: 1rem;
                    font-weight: 700;


                    @media only screen and (min-width: $bp-small) {
                                font-size: 1.2rem;
                        }
                }
    }

    &__accountinfo{

        display: flex;
        gap: 2rem;
        align-items: center;

        img{
            object-fit: contain;
            width: 2.2rem;
                                    cursor: pointer;
        }

        &--notes{         
            position: relative;

            span{
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                background-color: red;
                position: absolute;
                top: -.5rem;
                right: 0;
                border-radius: 50%;
            }
        }
    }

    &__userphoto{
        display: flex;
        align-items: center;
        gap: .4rem;
        font-family: Poppins;
        font-size: 16px;
         cursor: pointer;
      
       

         img {
         border-radius: 50%;
         }
     }

    a{

        text-decoration: none;
        
         color: #000000;
    }


    &__mmobilemenu {

    @media only screen and (min-width: $bp-small) {
          display: none;
        }


        img{

            width: 2rem;
            height: 2rem;
        }

        
    }
}

