 
.notificationsmodal__section{
    position: absolute;
    right: -240%;
    top: 150%;
   
    border: 1px solid #ccc;
    background-color: white;

    z-index: 999;
    border-radius: 2rem;
    // overflow: auto;
    opacity: 0;
    transform: scale(0.9);
 

    box-shadow: 0px 11px 15px 4px #6100c214;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8895902317880795) 89%);
    transition: all .3s;
    z-index: -9;
    // height: 30rem;
    // width: 33rem;


    &.active{
        display: initial;
        opacity: 1;
        transform: scale(1);
            z-index: 99;
    }
 

    @media only screen and (min-width: $bp-small) {
            right: 0;
            //   top: 0;
        }

    h3 {
            background-color: rgba(240, 236, 236, 0.185);
            font-size: 1.6rem;
            padding: 1rem 3rem;
        }

    h6{
        text-align: center;
        font-size: 1.4rem;

    &:hover{
        color: #b7b0b0;
    }
    }
}
.notificationsmodal{

        height: 30rem;
        width: 34rem;
   
       overflow: auto;

    


    &__container {
        // display:  flex;
        gap: 1rem;
        justify-content: end;
        // width: 85%;
        align-items: center;
        padding: 2rem;
        // overflow: auto;
     }

     img{
        object-fit: contain;
        width: 2rem;
     }

    li{
        background-color: white;
        padding: 1rem;
        margin-bottom: .5rem;
        // display: grid;
        justify-content: space-between;
        box-shadow: 0 .5rem 1rem  #fcfcfc;
        align-items: center;
        border-bottom: 1px dotted #b7b0b0;

        div{
            width: 100%;
            display: grid;
                justify-content: space-between;
             grid-template-columns: 20px 200px 3rem;
             gap: 1rem;
        }

     
        

      

        &:hover{
            background-color:rgba(255, 140, 0, 0.201);
        }

        p{
            

            &.not_read{
                font-weight: bold;
            }
        }


        a{
            padding: .5rem;
            &:hover {
                    background-color: rgba(255, 140, 0, 0.424);
                }
        }

    }


    &__time {

        font-size: 1.1rem;
        padding: .5rem;
        // background-color: #6c6c6cf1;
        text-align: right;
        font-weight: bold;
        color: #575353;
        

    }
    
}

