// SETTINGS SKELETON

.skeleton-uvh0zo6nab1:empty {
    position: relative;
    height: 400px;
    // background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), radial-gradient(circle 20px at 20px 20px, #F5F7F9 99%, transparent 0);
    background-repeat: repeat-y;
    background-size: 43% 133px, 90% 133px, 74% 133px, 52px 133px, 88px 133px, 40px 133px;
    background-position: left 12px top 111px, left 12px top 92px, left 12px top 73px, left 59px top 39px, left 59px top 21px, left 12px top 12px;
}

.skeleton-uvh0zo6nab1:empty:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 400px;
    -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 400px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), radial-gradient(circle 20px at 20px 20px, rgba(102, 102, 102, 1) 99%, transparent 0);
    background-repeat: repeat-y;
    background-size: 43% 133px, 90% 133px, 74% 133px, 52px 133px, 88px 133px, 40px 133px;
    background-position: left 12px top 111px, left 12px top 92px, left 12px top 73px, left 59px top 39px, left 59px top 21px, left 12px top 12px;
    animation: shineForSkeleton-uvh0zo6nab1 2s infinite;
}

@keyframes shineForSkeleton-uvh0zo6nab1 {
    to {
        -webkit-mask-position: 120% 0
    }
}


 
 

// STATEMENT SKELETON


    
    .skeleton-statement:empty {
            position: relative;
            height: 290px;
            // background-color: #ffffff;
            border-radius: 0px 0px 0px 0px;
            background-image: linear-gradient(#E0E0E0 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, #E0E0E0 16.5px, transparent 17.5px), linear-gradient(#E0E0E0 200px, transparent 0);
            background-repeat: repeat-y;
            background-size: 70% 290px, 35px 290px, 80% 290px;
            background-position: center top 244px, center top 236px, center top 20px;
        }
    
        .skeleton-statement:empty:before {
            content: ' ';
            position: absolute;
            z-index: 1000;
            width: 100%;
            height: 290px;
            -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
            -webkit-mask-repeat: repeat-y;
            -webkit-mask-size: 50px 290px;
            -webkit-mask-position: -20% 0;
            background-image: linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, rgba(102, 102, 102, 1) 16.5px, transparent 17.5px), linear-gradient(rgba(102, 102, 102, 1) 200px, transparent 0);
            background-repeat: repeat-y;
            background-size: 70% 290px, 35px 290px, 80% 290px;
            background-position: center top 244px, center top 236px, center top 20px;
            animation: shineForskeleton-statement 2s infinite;
        }
    
        @keyframes shineForskeleton-statement {
            to {
                -webkit-mask-position: 120% 0
            }
        }









        .skeleton-small:empty {
            position: relative;
            height: 100px;
            // background-color: #ffffff;
            border-radius: 0px 0px 0px 0px;
            background-image: linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), linear-gradient(#F5F7F9 6px, transparent 0), radial-gradient(circle 20px at 20px 20px, #F5F7F9 99%, transparent 0);
            background-repeat: repeat-y;
            background-size: 43% 133px, 90% 133px, 74% 133px, 52px 133px, 88px 133px, 40px 133px;
            background-position: left 12px top 111px, left 12px top 92px, left 12px top 73px, left 59px top 39px, left 59px top 21px, left 12px top 12px;
        }

        .skeleton-small:empty:before {
            content: ' ';
            position: absolute;
            z-index: 1000;
            width: 100%;
            height: 100px;
            -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
            -webkit-mask-repeat: repeat-y;
            -webkit-mask-size: 50px 100px;
            -webkit-mask-position: -20% 0;
            background-image: linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 6px, transparent 0), radial-gradient(circle 20px at 20px 20px, rgba(102, 102, 102, 1) 99%, transparent 0);
            background-repeat: repeat-y;
            background-size: 43% 133px, 90% 133px, 74% 133px, 52px 133px, 88px 133px, 40px 133px;
            background-position: left 12px top 111px, left 12px top 92px, left 12px top 73px, left 59px top 39px, left 59px top 21px, left 12px top 12px;
            animation: shineForSkeleton-small 2s infinite;
        }

        @keyframes shineForSkeleton-small {
            to {
                -webkit-mask-position: 120% 0
            }
        }






.skeleton-settings:empty {
    position: relative;
    height: 180px;
    // background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#E0E0E0 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, #E0E0E0 16.5px, transparent 17.5px), linear-gradient(#E0E0E0 100px, transparent 0);
    background-repeat: repeat-y;
    background-size: 60% 290px, 35px 290px, 70% 290px;
    background-position: center top 138px, center top 130px, center top 20px;
}

.skeleton-settings:empty:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 160px;
    -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 140px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, rgba(102, 102, 102, 1) 16.5px, transparent 17.5px), linear-gradient(rgba(102, 102, 102, 1) 100px, transparent 0);
    background-repeat: repeat-y;
    background-size: 60% 290px, 35px 290px, 70% 290px;
    background-position: center top 138px, center top 130px, center top 20px;
    animation: shineForskeleton-settings 2s infinite;
}

@keyframes shineForskeleton-settings {
    to {
        -webkit-mask-position: 120% 0
    }
}