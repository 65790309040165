.transactions{



    // width: 1072px;
    height: 406px;
    top: 623px;
    left: 328px;
    border-radius: 10px;
    padding: 1rem 0;
    width: 93%;
    margin: 0 auto;
    min-width: 56.9rem;
    // margin-top: 5%;
    display: none;


    &.backgroundColor{
    background: #FBFBFB;
    }



    @media only screen and (min-width: $bp-small) {

            width: 93%;
            margin: initial;
            // margin-top: 5%;
            display: block;

        }


    &__container {
        width: 90%;
        // max-width: 80rem;
        margin: 0 auto;
        // margin-top: 5%;
        position: relative;


        @media only screen and (min-width: $bp-small) {
            width: 90%;
            margin-left: 0;
            }
    }

    &__head{

        // font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        // display: flex;
        // justify-content: space-between;
      
        // margin:  0 auto;
        padding: 1rem 0;


        @media only screen and (min-width: $bp-small) {
                width: 100%;
            }
        


        p{
            cursor: pointer;
      
            // color:  #FF7A00;
            text-decoration: none;
            // font-size: 1.5rem;

            
        }

    

    
    }


    &__listcontainer{

        display: grid;
        grid-template-columns: minmax(500px, 990px) 1fr ;


        ul{
            margin-bottom: 0;
        }

    }

 


    &__item {   

        // font-size: 1.4rem;
       text-align: center;


       &.pethod{
        font-family: Montserrat;
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 1rem;

        span{
            width: 35%;
            // margin-left: 6.5rem;  
            font-weight: bold;
              }


        img {
            object-fit: contain;
            margin-left: 1rem;
            width: 2.5rem;



            }

       }
   
        

        &.amount {
        font-family: Montserrat;
        
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;

      
        }

        &.date {
            font-family: Montserrat;
            
            font-weight: 400;
            line-height: 20px;
            color: #747373;
            font-weight: bold;
        }

        &.type {
            font-family: Montserrat;                    
            font-weight: 400;
            line-height: 20px;
        }

        &.status {
        
            font-family: Montserrat;            
            font-weight: 400;
            padding: .4rem;
            border-radius: 40px;
            // display: flex;
            // gap: 1rem;

            

                span {
                        margin-left: -3rem;
                    }

                                 
                
        }

                        

  &.actions {

      position: relative;
      cursor: pointer;
    //   font-size: 2.5rem;
  }
    
         }

    &__item.head{
        color: #C0C0C0;

        text-align: center;


        &.amount {
            font-family: Montserrat;
                // font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
        }

      
        
        


    }



&__list {
    display: flex;
    // margin-top: 1rem;
    justify-content: space-between;
    // padding: 1rem 0;
    align-items: center;
    
    
   

    li{
        width: 25%;
        position: relative;


        p{
            
            width: 50%;
            padding: .5rem;

            border-radius: 1.5rem;
            text-align: center;
            margin: 0 auto;


            width: 106px;
            height: 30px;
            top: 737px;
            left: 1137px;
            border-radius: 40px;


            @media only screen and (min-width: $bp-small) {
            width: 70%;                             }


            &.fail, &.rejected {
                background-color: #FF7373;
                color: white;
            }


            &.pending {
                border: 1px solid #FF7A00;
                color: #FF7A00;


            }

            &.success {
                background-color:  #D3F5D3;;
                color: white;

                // border: 1px solid rgb(187, 230, 15);
                color:  #000000;


            }

            &.rejected {
                background-color: rgb(230, 36, 15);
                color: white;


            }

           &.cancelled {
                background-color: #FF7373;
                color: white;
                font-family: Montserrat;
                font-size: 16px;
               


            }
        }
    }

   
}

   ul {
       li {
           text-align: center;


           &.disable-li{
            pointer-events: none;
                user-select: none;
                color: gray;
           }
       }
   }




&__amount {

    font-weight: 900;
    // font-size: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    &--cash {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        span {
            // font-size: 1.3rem
        }

        img {
            object-fit: contain;
            width: 1rem;
        }
    }
}


}



.transactionslistmobile {
    // padding: 3rem 0;
    height: 50rem;


    &__container {

        position: relative;
          
    }


@media only screen and (min-width: $bp-small) {

      display: none;
    }

    &__container {

      
        margin: 0 auto;
        margin-top: 2rem;

        @media only screen and (min-width: $bp-small) {
        
                width: 90%;
            }
    }



    &__list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 50rem;

        li {
            // padding: 1rem 1rem;

            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 1px 11px -5px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0px 1px 11px -5px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 1px 11px -5px rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid #ccccccd1;
            background-color:white;

            p{
                //    font-size: 1.7rem;
                   font-weight: bold;


            }

            
        }
    }

    &__middle {
        display: flex;
        align-items: center;
        gap: 1.5rem;

  
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        

        p {

            // font-size: 1.5rem;
            font-weight: bold;
        }

        span {
            color: #000000;

        }
    }

    &__middleimg{

        width: 4rem;

      
    }

    &__created_at{
        // font-size: 1.4rem;
    }

    &__buttom{
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    &__status{
        border-radius: 1.5rem;
        // font-size: 1.3rem;
        padding: .3rem .5rem;
        width: 10rem;
        color: white;
        text-align: center;
    }

    &__status.pending{
        border: 1px solid #FF7A00;
        color: white;
                // color: #FF7A00;
        background-color: #FF7A00;;
 
       
    }

    &__status.successful,&__status.success {
        //  border: 1px solid rgb(187, 230, 15);
        color:  #000000;
        background-color: #D3F5D3;;
 

    }

        &__status.rejected {
        background-color: rgb(230, 36, 15);
        color: white;
    
    
        }


        

        &__amount {

            font-weight: 900;
            // font-size: 1rem;
            display: flex;
            gap: 2rem;
            position: relative;
            


            &--cash{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5rem;

                p{
                    // font-size: 1.2rem;
                }

                span{
                //    font-size:  1.4rem
                }

                img {
                        object-fit: contain;
                        width: 1rem;
                    }
            }

            

            p{
            }
        }

       
        &__actions {

            cursor: pointer;

            &.disable-li {
                    pointer-events: none;
                    user-select: none;
                    color: gray;
                }
        }
}


.transactions__homeheader{
    position: relative;

    &--container {
        // margin-left: 6.5%;
        // width: 85%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (min-width: $bp-small) {
                // width: 70%;
        
            }

        span {

                font-family: Montserrat;
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
            }

    }

    &--left{
        display: flex;
        align-items: center;
        justify-content: center;

        p{

        cursor: pointer;
    
        // color:  #FF7A00;
        text-decoration: none;
        // font-size: 15px;
        color: #FF7A00;
        margin-top: 1rem;
     
        }
 

        
    }
}