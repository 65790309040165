.app_content{

    width: 100%;
    // background-color: red;
    margin: 0 auto;

     height: 100svh;
    // overflow: hidden;
    overflow-x: hidden;
    position: absolute;
        

    @media only screen and (min-width: $bp-small) {
        width: 80%;
        right: 0;
        }
}


.dashboardaccount{

        // width: 100%;
        margin: 0 auto;
        // margin-top: 2rem;
        margin-bottom: 5%;


    &__container {  


        width: 94%;
        margin: 0 auto;

        @media only screen and (min-width: $bp-small) {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 2rem;

                width: initial;
                margin: initial;
                width: 96%;
            }
    }

    &__summary{
       
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }



   
   

    &__cards {
        display: flex;
        gap: 1rem;
        margin: 0 auto;

        @media only screen and (min-width: $bp-small) {
            margin: initial;
            }
    }

    &__savedcards{
        display: flex;
        flex-direction: column;
        // gap: 2rem;
        // background-color:  #FBFBFB;
        // width: 96%;
        margin: 0 auto;
        padding: 1rem;
       

        @media only screen and (min-width: $bp-small) {
            // padding: 2rem;
            width: 100%;
            }

        li{
            display: flex;
            overflow: auto;
            gap: 1rem;



                @media only screen and (min-width: $bp-small) {
                      flex-direction: column;
                    }
        }


 
    }

    &__head{
        display: flex;
        justify-content: space-between;
        // padding: 1rem 0;
        align-items: center;

        p{
            font-family: Poppins;
                font-size: 22px;
                font-weight: 500;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
        }

        img {
            object-fit: contain;
            // border: 1px solid red;
            padding: .5rem;
            // border-radius: 50%;
            // border: 2px solid #FF7A00;
            cursor: pointer;
            // width: 2.5rem;
           width: 4rem;
           height: 4rem;


            @media only screen and (min-width: $bp-small) {
                  padding: 1rem;
                width: 4rem;
                }
        }
    }



    @media only screen and (min-width: $bp-small) {
        display: flex;
        gap: 1rem;
    }
}

.apexcharts{

    padding: 2rem 0;
}

::-webkit-scrollbar{
    display: none;
}

// .alert.alert-danger{

//      color: #ff5100;
// }

// .alert.alert-success {

//     font-size: 1.4rem;
//     color: green;
// }



.loading{
position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
     height: 200px;
    //  background-color: #ffffff;
     line-height: 200px;

}

a{
    text-decoration: none;
}


.loader {
    position: relative;
}

.loader:before,
.loader:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    position: relative;
    margin: 10px auto;
    border-radius: 50%;
    background: #000000;
    animation: left 1s infinite ease-in-out;
}

.loader:after {
    background: #FF3D00;
    animation: right 1s infinite ease-in-out;
}


@keyframes right {

    0%,
    100% {
        transform: translate(-15px)
    }

    50% {
        transform: translate(15px)
    }
}

@keyframes left {

    0%,
    100% {
        transform: translate(15px)
    }

    50% {
        transform: translate(-15px)
    }
}




// FUND MODAL
.fundmodal{
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.109);
    height: 120px;
    width: 130px;
    // padding: 3rem 4rem;
    display: none;
    z-index: 99;

    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0px 11px 15px 4px #6100c214;
background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8895902317880795) 89%);

    &__container {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        /* Adjust the width as needed */
        // height: 200px;
        /* Adjust the height as needed */
        text-align: center;

        li.active{
            background-color: #FF7A00;
            color: white;
        }



        h6 {
                font-size: 10px;
            }
  
            a{
                color: #FF7A00;
                font-weight: bold;
            }
    }

    ul{
        display: flex;
        flex-direction: column;
        gap: .4rem;

        li{
            font-size: 12px;
            // background-color: #706666;
            padding: .5rem 1rem;
            cursor: pointer;


    &:hover,
        &:active {
            background-color: #FF7A00;
            color: #FBFBFB;
        }
            


        }

    }

  


    p, ul li {
        text-align: left;
    }
}

.isactive{
    display: initial;
}



// .invalid-feedback{


//     color: red;
//     font-size: 1.2rem;
// }


.home__page{

    padding-bottom: 10%;
    // margin-top: 5rem;
}


 
.action_button {
    font-size: 1.6rem;
    background: black;
    padding: 0.8rem 1rem;
    color: white;
    cursor: pointer;
    border-radius: 1.5rem;
    transition: all 0.25s;
    display: flex;
    width: 10rem;
    text-align: center;
    justify-content: center;

 
    width: 153px;
    // height: 40px;
  
    border-radius: 5px;
    background-color:  #FF7A00;


    a {
            color: white;
            text-decoration: none;

    }
}

 

.page {
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem;
    

    @media only screen and (min-width: $bp-small) {
        margin: initial;
        margin-top: 5rem;
        
        }


    &__container {

        // width: 90%;
        margin: 0 auto;
        padding-bottom: 10%;
        height: 100vh;
    }

 
}

.colum{
    margin:2rem 0;
    width: 90%;
    display: flex;
    margin:  0 auto;
    margin-top: 5%;
    align-items: center;
 


        @media only screen and (min-width: $bp-small) {
                margin-left: 0;
                width: 95%;
                    // margin-right: initial;
            }
}

.link{
    position: relative;

    font-size: 1.6rem;
    width: 8rem;
    cursor: pointer;
    z-index: 99;
    width: 40%;

    @media only screen and (min-width: $bp-small) {
            margin-left: 0;
            width: 30%;
            // margin-right: initial;
        }

    &__container {

        width: 100%;
        display: flex;
        gap: .8rem;
        justify-content: center;
    }

    img{
        width:  1rem;
        object-fit: contain;
    }
    
      a{

        color: #756666;
      }
     
}


.loadercontainer {
    position: relative;
}

/* HTML: <div class="loader"></div> */
.loadersecond {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;

    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        background-color: #ffffff;
 }

@keyframes l5 {
    0% {
        box-shadow: 20px 0 #000, -20px 0 #0002;
        background: #000
    }

    33% {
        box-shadow: 20px 0 #000, -20px 0 #0002;
        background: #0002
    }

    66% {
        box-shadow: 20px 0 #0002, -20px 0 #000;
        background: #0002
    }

    100% {
        box-shadow: 20px 0 #0002, -20px 0 #000;
        background: #000
    }
}


.requestslists__type{
    font-size: 1.1rem;
    color: #75666696;

    
}

 .row-tab {
     display: flex;
     justify-content: space-between;
     padding: 1rem 0;


     p {
        border: 1px solid #FF7A00;
        padding: 1rem;
        border-radius: .5rem;
        cursor: pointer;
      background-color: white;


        &:hover,&:active{
            background-color: #FF7A00;
            color: white;

        }


     }

     &__item{

     &.active{
        background-color: #FF7A00;
        color: white;
     }
    }
 }


 .paymentmethodtab{

    margin: 1.5rem 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    

    ul {

        display: flex;
        gap: 1rem;
        justify-content: space-around;

        @media only screen and (min-width: $bp-small) {
               
                justify-content: initial;
            }

        li {
        cursor: pointer;           
        width: 100px;
        height: 90px;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: box-shadow .5s;
        // padding: 0 1rem;

        
 
        @media only screen and (min-width: $bp-small) {
            cursor: pointer;
            width: 143px;
            min-width: 50px;
            height: 95px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            }



        div {
        
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .5rem;
            }


        img{
            width: 33px;
            height: 33px;
            top: 239px;
            left: 404px;
            object-fit: contain;

        }

        &:hover{

            border: 1px solid #D8D8DD;

            box-shadow: 0px 4px 4px 0px #00000040;;


        }

                    
        }
    }

    &__item{
        border-top: 1px solid #EEEEEE;
         border-right: 1px solid #EEEEEE;
                        border-bottom: 1px solid #EEEEEE;
        margin-right: -1.5rem;
        transition: all .3 ;

        p{
            font-size: 1.4rem;
        }

 
     

        &.active{
        border: 1px solid #D8D8DD;
        box-shadow: 0px 4px 4px 0px #00000040;
 

        }

        &:hover,
        &.active {
            // background-color: #FF7A00;
            color: #000;

            p {
                // color: #FF7A00;
                font-weight: bold;
            }

    //    background-color: #FF7A00;
            
        img {
            filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(10%) contrast(100%);

        }

        }

        
    }
 }


 .page__form{

    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin-top: 5%;






        @media only screen and (min-width: $bp-small) {
            display: flex;
                flex-direction: column;
                gap: 2rem;
                width: 70%;
            }
 }



 .page_button{

        width: 270px;
        height: 44px;
        border-radius: 8px;
        gap: 10px;
        background-color: #FF7A00;
        outline: none;
        border: 0;
        color: white;
        cursor: pointer;
        margin: 0 auto;

        @media only screen and (min-width: $bp-small) {
                width: 365px;
    }

        
 }


 .css-13cymwt-control{

    border-color: none !important;
    border-style: none !important;
    // z-index: 10;

    &:active{
        border-color: none !important;
        border-style: none !important;
        outline: none !important;
        border: none !important;
    }

    &:focus {
        border: none !important;
        outline: none !important;
        border-color: none !important;
         
    }
    
 }


 .sendmoneypagebreadcumb {

    &__container{

        display: flex;
        flex-direction: column;
        gap: 1rem;


        @media only screen and (min-width: $bp-small) {
             flex-direction: row;
             justify-content: space-between;
             width: 100%;
             align-items: center;



         
            }

        
    }


    &__sendm{
        width: 100%;

    }
 }

 .requesterimg{
    display: flex;


        img{

        width: 10rem;
        margin: 0 auto;
     }
 }

 .webcam{
 
    video{
        width: 100%;
        height: 30rem;
    }

    &__button{
        margin-top: 2rem;

        background-color: #EEEEEE;
        border: 0;
        padding: 1rem 1.5rem;
        cursor: pointer;

    }
 }



 .emptylist {
    display: flex;
    flex-direction: column;

    align-items: center;
    height: 406px;
    // gap: 2rem;
    width: 90%;
    margin: 0 auto;

    @media only screen and (min-width: $bp-small) {
    
                // justify-content: space-around;
    
        }


    h4,p{

    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    }

    h4{
     
            text-align: left;
    }

    p{
        
            color:  #A3A2A2;;
    }

        @media only screen and (min-width: $bp-small) {
    
            h4,
            p {
                font-size: 18px;
            }
    
    
    
        }

 }

 

 .pageform__group.select{
            transition: all 0.25 linear;


&:focus {
        border: none;
        outline: none;
        border: 2px solid #0000FF;
        transition: all 0.25 linear
        
    }

 }


.filter_modal{


    position: absolute;
    width: 60%;
    // position: absolute;
    top: 0;
        background-color: white;
            border: 1px solid #b7b7b7;

    // position: absolute;
    right: 0;    
    // height: 35rem;
    padding: 2rem 1rem;
    max-width: 30rem;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 1rem 1.5rem 0 #fcfffc;
    // display: none;
    
    opacity: 0;
    transform: translateX(300px);
    transition: all .3s;
    transition: transform .3s ease, opacity .3s ease;
    z-index: -99;
    box-shadow: 0px 11px 15px 4px #6100c214;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8895902317880795) 89%);

    @media only screen and (min-width: $bp-small) {
        width: 50%;        
        
        }

    &__container {
    height: 40rem;
        margin-top: 70%;


        @media only screen and (min-width: $bp-small) {
            height: 40rem;
            margin-top: 20%;
    
            }
    }


    &.active{
        display: initial;
        opacity: 1;
        // transition: all .3s;
        transition: transform .3s ease, opacity .3s ease;
        z-index: 99;
        transform: translateX(0);


    }
}



 .offcanvas{




    &__header {
        font-size: 2rem;
    }
 }

 .offcanvas-body{
display: flex;
    flex-direction: column;
    // justify-content: space-around;
    gap: 1rem;
    // margin-top: 1rem;
  
 }

 .offcanvas-footer{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    

    button {
        width: 10rem;
        padding: .5rem 1rem;
        outline: none;
        border: 0;
        cursor: pointer;
        padding: 1rem 1.2rem;

       
            &:hover {
                    opacity: .7;
                }


        &.btn-success{
            background: rgb(118, 140, 118);
            color: white;

        
        }

        &.filter-btn{
            background-color: #fcfffc;
        }



    }
 }

 .filter__area {

// background-color: #fcfffc;
position: relative;
width: 100%;


figure{
    // position: absolute;
   
    // top: -1rem;
    right: 0px;
    // margin: 2rem 0;
    cursor: pointer;
    // display: flex;
    align-items: center;
    z-index: 9;

 
    font-family: Montserrat;
    font-size: 16px;
       text-align: right;
    color:  #000000;;
    padding: .5rem 1rem;
   

 
  
//  width: 5rem;
&:active,
&:hover {

    border-radius: 1.5rem;
    box-shadow: 1px solid red;


     
}
}


 }

 .filter_modal_label{

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
 }

 .filter_modal_head{
    font-size: 2rem;
    margin-bottom: 1rem;
 }

 

 .text-large{

    font-size: 2rem;
 }


 .simgplyimg{

    width: 2rem;

    img{
        width: 100%;
        object-fit: contain;
    }
 }




//  refresh button
 @keyframes rotating {
     from {
         -ms-transform: rotate(0deg);
         -moz-transform: rotate(0deg);
         -webkit-transform: rotate(0deg);
         -o-transform: rotate(0deg);
         transform: rotate(0deg);
     }

     to {
         -ms-transform: rotate(360deg);
         -moz-transform: rotate(360deg);
         -webkit-transform: rotate(360deg);
         -o-transform: rotate(360deg);
         transform: rotate(360deg);
     }
 }

//  body {
//      padding: 50px;
//      background: #fcecd7;
//  }

 .refresh {
     background: #FFFFFF;
     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
     padding: 8px;
     border: 0;
     border-radius: 4px;
     width: 40px;
     height: 40px;
     margin: 0 auto;
     display: block;
     

     .icon {
         fill: #323232;
         width: 1.2rem;
     }

     &:hover {
         background: #def4d7;
         cursor: pointer;
     }

     &:active {
         .icon {
             fill: #5ac938;
         }
     }

     &.refreshing {
         background: #def4d7;
         cursor: wait;

         .icon {
             fill: #5ac938;
             -webkit-animation: rotating 1.2s linear infinite;
             -moz-animation: rotating 1.2s linear infinite;
             -ms-animation: rotating 1.2s linear infinite;
             -o-animation: rotating 1.2s linear infinite;
             animation: rotating 1.2s linear infinite;
         }
     }
 }