$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; //900px
$bp-small: 48em; // 768px
$bp-smallest: 31.25em; // 500px

//COLLORS
// $color-primary: #55c57a;
// $color-primary-light: #7ed56f;
// $color-primary-dark: #28b485;

$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light: #eaeaea;
$color-grey-light-1: #f8f8f8;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

//
$color-primary: #2196f3;
$color-ascent: #469dc9;
$color-primary-light: #c5cae921;
$color-shadow: rgba(28, 115, 196, 0.357);
$color-shadow1: rgba(99, 172, 228, 0.227);
// $color-primary: rgba(28, 116, 218, 0.753);
// $color-ascent: rgba(28, 116, 218, 0.753);
// $color-primary-light: rgba(96, 159, 232, 0.048);
// $color-shadow: rgba(28, 115, 196, 0.357);

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-yellow-light: darkkhaki;

$color-grey-light: #eaeaea;
$color-grey-light-1: #f8f8f8;
$color-grey-dark: #808080e7;
$color-grey-dark-1: #333;

$color-darkgreen: darkgreen;
$color-green: green;
$color-white: #ffffff;
$color-black: #212121;

//font sizes

$margin-bottom-1: 1rem;
$margin-bottom-2: 2rem;
$oneRem: 1rem;

$margin-left-1: 1rem;
$margin-left-3: 3rem;

//////////////////////////////////////////////
$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
//$bp-medium: 61.87em ; //900px
$bp-medium: 56.25em; //900px
$bp-small: 47.12em; // 770px
$bp-smallest: 31.25em; // 500px
$bp-600: 31.25em; // 500px

//
// $color-primary: #f39c12;
$color-base: #16181d;
$color-nav: #c3c9d4;
$color-bc: #a1abbd;
$color-white: #fff;
// $color-primary-light: #1e2126;
$color-black: #000;

//font sizes

$margin-bottom-1: 1rem;
$margin-bottom-2: 2rem;

$margin-left-1: 1rem;
$margin-left-3: 3rem;
