.app_container{
    background-color: white;
    display: flex;
}

 


.nav__containermain , 
.nav__container  {

    position: fixed;
    height: 100svh;
}

.nav__containermain { 
    //  background-color: rgba(83, 65, 65, 0.308);
    width: 100%;
    // display: none;    
    opacity: 0;
    z-index: -99;
    transition: all .3s;
    transform: translateX(-200px);


     &.showNav {
        display: initial;
        transition: all .3s;
        opacity: 1;
        z-index: 999;
        transform: translateX(0px);
     }

    @media only screen and (min-width: $bp-small) {
        display: flex;
        grid-template-columns: repeat(2, 1fr);
        width: 20rem;
        opacity: 1;
        z-index: 99;
        transform: translateX(-0px);
    }


}

.nav__container {
    background-color: white;
    width: 50%;
    flex-direction: column;
    box-shadow: 2px 0px 90px 0px #6100C233;
    overflow: auto;

    

    H6{
    color: black;
    height: 19px;
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 80%;
    margin: 0 auto;    
    padding: .5rem;
    margin-bottom: 1rem;
    color:  #DBDBDB;
    }

    @media only screen and (min-width: $bp-small) {
        display: flex;
        grid-template-columns: repeat(2, 1fr);
        width: 20rem;
    }


    
}

nav{
    background-color: white;
    // width: 100%;

    img{

        // font-size: 2rem;
        // width: 18px;
        height: 16px;
        top: 193px;
        left: 41px;
        border: 2px;

    }

    .nav_list {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        width: 80%;
        margin: 0 auto;

        padding: .5rem;
            
        border-radius: 10px;
        



    }

   .nav_item {
        display: flex;
        gap: .5rem;
        padding: .5rem;
        
        border-radius: 10px;
        transition: all .25s ;
        cursor: pointer;

        &:hover{
            background-color: #FF7A00;

            p {
                    color: white;

                }
            
        }

        &:hover,
        &.isactive{
            background-color: #FF7A00;

            p{
             color: white;
            }

            img {
            filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(100%);

            }

        }
    }

  

    ul {

        li {

            p {
                color: #5B5858;
                font-family: Montserrat;
                    font-size: 1.3rem;
                    font-weight: 600;
                    line-height: 1.6rem;

                    
                
            }
        }
    }

}

.nav_home {
    // background-color: #FF7A00;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    gap: .5rem;
    width: 80%;
    margin:  0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: .5rem;

    border-radius: 10px;



    p {
        color: black;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    img{
        width: 18.33px;
        height: 17.5px;
        object-fit: cover;

    }

   

&:hover,
    &.ishomeactive{
        background-color: #FF7A00;

        img {
                filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(100%);
        
            }
        

       

p{
    color: white;
}

    }
}




.paylogo{
    display: flex;
    justify-content: center;
    padding:2rem 0;
    width: 19rem;

    img{

    width: 80%;
    width: 130px;
    height: 15px;  


        @media only screen and (min-width: $bp-small) {
                width: 80%;
                width: 160px;
                height: 24px;
            }

    }



    @media only screen and (min-width: $bp-small) {
        width: 20rem;
    }


          
}

