.pagination{


    &__container {
        display:  flex;
        gap: 1rem;
        justify-content: end;
        // width: 90%;
        align-items: center;
    }
    &__item {

        font-size: 1.5rem;
        // background-color: #ccc;
        padding: .5rem;
        cursor: pointer;
        border: 1px solid #ccc;

       &:active, &:hover {
            background-color: antiquewhite;
        }
    }

    p{
        font-size: 1.6rem;
    }
}